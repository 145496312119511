<template>
    <div class="vehicle-dispatch" :class="{'isLargeScreen': isLargeScreen}">
        <ul class="project-tj">
            <li class="item " :class="{'active':activeProject===1}" @click="screenProject(1)">
                <span class="lable-text">全部任务工地</span>
                <span class="num-style">{{ projectsStatistics.all }}</span>
            </li>
            <li class="item" :class="{'active':activeProject===2}" @click="screenProject(2)">
                <i class="pre-icon wys-pre-icon"></i>
                <span class="lable-text">未运输</span>
                <span class="num-style">{{ projectsStatistics.notWork }}</span>
            </li>
            <li class="item" :class="{'active':activeProject===3}" @click="screenProject(3)">
                <i class="pre-icon wwc-pre-icon"></i>
                <span class="lable-text">未完成</span>
                <span class="num-style">{{ projectsStatistics.notFinished }}</span>
            </li>
            <li class="item" :class="{'active':activeProject===4}" @click="screenProject(4)">
                <i class="pre-icon ysz-pre-icon"></i>
                <span class="lable-text">运输中</span>
                <span class="num-style">{{ projectsStatistics.trans }}</span>
            </li>
        </ul>
        <ul class="car-tj">
            <li class="item all-car" :class="{'active':activeCar===1}" @click="screenCar(1)">
                <p class="num-style">
                    {{ carStatistics.all }}
                </p>
                <p class="lable-text">
                    全部车辆
                </p>
            </li>
            <li class="item trans-car" :class="{'active':activeCar===2}" @click="screenCar(2)">
                <p class="num-style">
                    {{ carStatistics.trans }}
                </p>
                <p class="lable-text">
                    运输中
                </p>
            </li>
            <li
                class="item back-car"
                :class="{'active':activeCar===3}"
                @click="screenCar(3)"
            >
                <p class="num-style">
                    {{ carStatistics.back }}
                </p>
                <p class="lable-text">
                    返场中
                </p>
            </li>
            <li
                class="item nowork-car"
                :class="{'active':activeCar===4}"
                @click="screenCar(4)"
            >
                <p class="num-style">
                    {{ carStatistics.notWork }}
                </p>
                <p class="lable-text">
                    未运输
                </p>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        projectsStatistics: {
            type: Object,
        },
        carStatistics: {
            type: Object,
        },
        isLargeScreen: {
            type: [Boolean],
            required: false, // 是否必传
        },
        resetActive: {
            type: [Number],
        },
    },
    data() {
        return {
            activeProject: 1, // 选中工地
            activeCar: 1,
        };
    },
    watch: {
        resetActive() {
            this.activeProject = 1;
            this.activeCar = 1;
        },
    },
    computed: {},
    methods: {
        // 切换工程地址
        screenProject(num) {
            this.activeProject = num;
            this.$emit('projectsClick', num);
        },
        // 切换筛选车辆
        screenCar(num) {
            this.activeCar = num;
            this.$emit('carClick', num);
        },
    },
    created() {
    },
    mounted() {},
};
</script>
<style lang="stylus">
.vehicle-dispatch
    position absolute
    right .1rem
    top .6rem
    width 2rem
    padding .1rem
    .project-tj
        .item
            width 1.68rem
            height .58rem
            background url(../../../assets/images/productionManagement/bg_normal.png) no-repeat
            background-size 100% 100%
            color #fff
            text-align center
            overflow hidden
            margin-bottom .1rem
            line-height .58rem
            text-align center
            cursor pointer
            .lable-text
                font-size .16rem
                float left
                margin-right .05rem
            .num-style
                font-family QuartzLTStd
                font-size .26rem
                float left
            .pre-icon
                width .3rem
                height .3rem
                background url(../../../assets/images/productionManagement/flag_red2.png) no-repeat
                background-size 100% 100%
                float left
                margin .15rem
                &.wwc-pre-icon
                    background url(../../../assets/images/productionManagement/flag_blue2.png) no-repeat
                    background-size 100% 100%
                &.ysz-pre-icon
                    background url(../../../assets/images/productionManagement/flag_green2.png) no-repeat
                    background-size 100% 100%
            &:first-child
                .lable-text
                    margin 0 .05rem 0 .2rem
            &.active
                background url(../../../assets/images/productionManagement/bg_highlight.png) no-repeat
                background-size 100% 100%
    .car-tj
        margin-top .4rem
        .item
            width 1.6rem
            height .6rem
            text-align center
            background #fff
            margin-bottom .1rem
            box-shadow 0 0 10px #888888
            cursor pointer
            .num-style
                font-family QuartzLTStd
                font-size .26rem
            .lable-text
                font-size .16rem
            &.all-car
                color #143382
            &.trans-car
                color #0048B8
            &.back-car
                color #FF7E00
            &.nowork-car
                color #D6010F
            &.active
                background:linear-gradient(90deg,rgba(71,124,232,1),rgba(93,172,234,1));
                color #fff
    &.isLargeScreen
        width auto
        padding-right 0
        top auto
        bottom 0.1rem
        padding-bottom 0.8rem
        .project-tj
            .item
                margin-right 0
        .car-tj
            width 7rem
            position absolute
            margin-top 0.2rem
            right 0
            li
                float left
                margin-left 0.2rem
                margin-bottom 0
                &:first-child
                    margin-left 0
</style>